import React, { useContext, useState } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

function SyncGaliaTC({ promo, galiaTC, setMajSite }) {
  const { url } = useContext(AppContext);
  const [save, setSave] = useState(true);
  const [msg, setMsg] = useState(null);
  const [selected, setSelected] = useState(null);

  const _TO_DAY = new Date().toISOString();

  const saveSync = () => {
    setSave(false);
    let uri = url + promo["@id"].slice(1);
    let data = [...promo.tc, selected.toString()];
    updateTC(uri, data);
  };

  const supSync = (TCid) => {
    setSave(false);
    let uri = url + promo["@id"].slice(1);
    let data = promo.tc.filter((t) => t !== TCid);
    updateTC(uri, data);
  };

  const updateTC = (uri, data) => {
    UpdateBddProvider(uri, { tc: data }).then((res) => {
      if (typeof res === "object" && res.id) {
        setSelected(null);
        setSave(true);
        setMajSite(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };

  return (
    <li className="list-group-item">
      {msg !== null && <MsgAlert msg={msg.txt} type={msg.type} />}
      <h4>Troncs communs liés à la promo</h4>

      {promo.tc.map((t) => {
        let name = galiaTC.find((g) => g.ID === parseInt(t)).Libelle_Court;
        return (
          <span key={t} className="badge badge-warning ml-2">
            {name}{" "}
            <span
              role="button"
              className="text-danger ml-2"
              title="supprimer"
              onClick={() => {
                if (window.confirm("Supprimer le TC ?")) {
                  supSync(t);
                }
              }}
            >
              {save ? "X" : <SmallSpinner />}
            </span>
          </span>
        );
      })}

      <div className="form-inline mt-2">
        <select
          className="form-control custom-select custom-select-sm"
          name="TC_select"
          id="TC_select"
          value={selected || ""}
          onChange={(e) => setSelected(e.target.value)}
        >
          <option value="" disabled>
            sélectionnez un TC
          </option>
          {galiaTC
            .filter(
              (t) =>
                !promo.tc.includes(t.ID.toString()) &&
                (t.Date_Fin_Prevue ? t.Date_Fin_Prevue >= _TO_DAY : true)
            )
            .map((t) => (
              <option key={t.ID} value={t.ID}>
                {t.Libelle_Court}{" "}
                {t.Date_Fin_Prevue === null &&
                  '-- (Le champs "Date de fin prevue" est vide dans Galia)'}
              </option>
            ))}
        </select>
        <button
          className="btn btn-outline-success btn-sm ml-2"
          title="enregistrer"
          onClick={() => {
            saveSync();
          }}
          disabled={selected === null}
        >
          {save ? "Ajouter" : <SmallSpinner />}
        </button>
      </div>
    </li>
  );
}

export default SyncGaliaTC;
