import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import PostBddProvider from "../../../Providers/PostBddProvider";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import dateFormatedReverse from "../../tools/DateFormatedReverse";
import { autoCloseMsg } from "../../tools/messagesUtils";
import { triByName } from "../../tools/sortUtils";

const EditSMEC = ({ smec, setMaj, site }) => {
  const _EVAL = [
    {
      name: "Subit",
      value: 0,
      icons: ["minus", "minus"],
      color: "danger",
    },
    {
      name: "Exécute",
      value: 1,
      icons: ["minus"],
      color: "warning",
    },
    {
      name: "Maîtrise",
      value: 2,
      icons: ["plus"],
      color: "green-light",
    },
    {
      name: "Maîtrise totale",
      value: 3,
      icons: ["plus", "plus"],
      color: "success",
    },
  ];

  const { user, url } = useContext(AppContext);

  const [blocs, setBlocs] = useState([]);
  const [saved, setSaved] = useState(false);
  const [suivi, setSuivi] = useState({});
  const [evaluateurs, setEvaluateurs] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    const loadEvaluateur = async () => {
      let uri = url + "api/list_users_suivi";
      await PostBddProvider(uri, {
        site: parseInt(site.split("/").pop()),
      }).then((res) => {
        let json = JSON.parse(res);
        addIRI(json);
      });
    };
    if (suivi.flow) {
      loadEvaluateur();
    } else {
      setEvaluateurs([]);
    }
  }, [suivi.flow, site, url]);

  useEffect(() => {
    if (smec !== null) {
      let tmp = JSON.parse(JSON.stringify(smec));
      setSuivi({
        ...tmp,
        createdAt: dateFormatedReverse(tmp.createdAt),
        validateAt: dateFormatedReverse(tmp.validateAt),
        evaluateur: tmp.evaluateur ? tmp.evaluateur["@id"] : undefined,
      });
      loadBlocs();
      saved && setSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smec]);

  const addIRI = (datas) => {
    let JSON = datas.map((data) => {
      return { ...data, "@id": `/api/users/${data.id}` };
    });
    setEvaluateurs(JSON);
  };

  const loadBlocs = () => {
    let uri = url + "api/bloc_afests?formation=" + smec.promo.formation;
    GetBddProvider(uri).then((res) => {
      if (typeof res === "object") {
        setBlocs(res["hydra:member"]);
        setLoaded(true);
      } else {
        setLoaded(true);
        setMsg({
          txt: "Erreur de chargement des blocs de compétence",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
      }
    });
  };

  const removeCompetence = (comp) => {
    let tmp = [...suivi.notes];
    tmp.forEach((n, i) => {
      if (n.competence["@id"] === comp["@id"]) {
        tmp.splice(i, 1);
      }
    });
    setSuivi((prev) => ({ ...prev, notes: tmp }));
  };

  const addCompetence = (comp, b) => {
    let competence = { ...comp, bloc: b };
    let tmp = [...suivi.notes, { competence: competence }];
    setSuivi((prev) => ({ ...prev, notes: tmp }));
  };

  const editNote = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = tmp.findIndex(
      (n) =>
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
    );
    if (index >= 0) {
      tmp[index] = { ...tmp[index], note: parseInt(val) };
      setSuivi((prev) => ({ ...prev, notes: tmp }));
    }
  };

  const editNoteComment = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = tmp.findIndex(
      (n) =>
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
    );
    if (index >= 0) {
      tmp[index] = { ...tmp[index], commentaire: val };
      setSuivi((prev) => ({ ...prev, notes: tmp }));
    }
  };

  const editComment = (val, blocSelect, compSelect) => {
    let tmp = suivi.notes;
    let index = tmp.findIndex(
      (n) =>
        n.competence["@id"] === compSelect &&
        n.competence.bloc["@id"] === blocSelect
    );
    // tmp.forEach((n, i) => {
    //   if (
    //     n.competence["@id"] === compSelect &&
    //     n.competence.bloc["@id"] === blocSelect
    //   ) {
    //     index = i;
    //   }
    // });
    if (index >= 0) {
      tmp[index] = { ...tmp[index], activities: val };
      setSuivi((prev) => ({ ...prev, notes: tmp }));
    }
  };

  const validate = () => {
    let valid = true;
    suivi.notes.forEach((n) => {
      // if (
      //   n.note === undefined ||
      //   n.activities === undefined ||
      //   n.activities.length < 20
      // ) {
      //   valid = false;
      // }
      if (n.note === undefined) {
        valid = false;
      }
    });
    if (
      suivi.comment === undefined ||
      suivi.comment === null ||
      suivi.comment === ""
    ) {
      valid = false;
    }
    if (
      suivi.flow &&
      (suivi.evaluateur === undefined ||
        suivi.evaluateur === null ||
        suivi.evaluateur === "")
    ) {
      valid = false;
    }
    // if (suivi.tuteur === undefined || suivi.tuteur === null) {
    //   valid = false;
    // }
    if (saved) {
      valid = false;
    }
    if (!loaded) {
      valid = false;
    }
    return valid;
  };

  const handleSubmit = async () => {
    let tempNotes = [...suivi.notes];
    let data = {};
    if (
      suivi.evaluateur &&
      (smec.evaluateur === undefined ||
        suivi.evaluateur !== smec.evaluateur["@id"])
    ) {
      data = {
        ...data,
        evaluateur: suivi.evaluateur,
      };
    }
    if (suivi.comment !== smec.comment) {
      data = {
        ...data,
        comment: suivi.comment,
      };
    }
    if (suivi.flow !== smec.flow) {
      data = {
        ...data,
        flow: suivi.flow,
      };
    }
    if (suivi.createdAt !== dateFormatedReverse(smec.createdAt)) {
      data = {
        ...data,
        createdAt: suivi.createdAt,
      };
    }

    if (JSON.stringify(data) !== "{}") {
      let save = await UpdateBddProvider(url + suivi["@id"].slice(1), data);
      if (!save.id) {
        setLoaded(true);
        setMsg({
          txt: "Erreur d'enregistrement de l'évaluation",
          type: "danger",
        });
        autoCloseMsg(setMsg, 5000);
        return;
      }
    }

    let erreurs = [];
    tempNotes.forEach(async (n, i) => {
      let noteInitaile = smec.notes.find((note) => note["@id"] === n["@id"]);
      if (noteInitaile === undefined) {
        let note = { ...n, competence: n.competence["@id"], eval: smec["@id"] };
        let saveNote = await PostBddProvider(url + "api/note_afests", note);
        if (typeof saveNote !== "object" || !saveNote.id) {
          erreurs = [...erreurs, n.competence.name];
        }
      } else if (
        noteInitaile.note !== n.note ||
        noteInitaile.activities !== n.activities ||
        noteInitaile.commentaire !== n.commentaire
      ) {
        let note = {
          activities: n.activities,
          note: n.note,
          commentaire: n.commentaire,
        };
        let saveNote = await UpdateBddProvider(url + n["@id"].slice(1), note);
        if (typeof saveNote !== "object" || !saveNote.id) {
          erreurs = [...erreurs, n.competence.name];
        }
      }
      if (i + 1 === tempNotes.length) {
        if (erreurs.length > 0) {
          setLoaded(true);
          setMsg({
            txt:
              "Erreur d'enregistrement de " + erreurs.length + " competences",
            type: "danger",
          });
          autoCloseMsg(setMsg, 5000);
        } else {
          setSaved(true);
          setLoaded(true);
          setMsg({
            txt: "L'évaluation a bien été modifiée",
            type: "success",
          });
          autoCloseMsg(setMsg, 5000);
          setMaj(true);
        }
      }
    });
  };

  const ModalBodySaved = () => {
    return (
      <div className="modal-body">
        {msg !== null && (
          <div className="mb-3">
            <MsgAlert
              msg={msg.txt}
              type={msg.type}
              close={() => autoCloseMsg(setMsg)}
            />
          </div>
        )}
        <h3 className="text-info">l'évaluation a bien été modifiée !</h3>
      </div>
    );
  };

  return (
    <div
      className="modal fade"
      id="ModalEditSMEC"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      {!suivi["@id"] ? (
        <div
          className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          Loading...
        </div>
      ) : (
        <div
          className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header bg-info text-white">
              <h5 className="modal-title">
                <FontAwesomeIcon icon="edit" /> Suivi Mensuel en Entreprise des
                Compétences
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            {saved ? (
              <ModalBodySaved />
            ) : (
              <div className="modal-body">
                <MsgAlert
                  msg="Ne sélectionnez que les compétences abordées sur la période."
                  type="danger h4"
                />
                {msg !== null && (
                  <div className="mb-3">
                    <MsgAlert
                      msg={msg.txt}
                      type={msg.type}
                      close={() => autoCloseMsg(setMsg)}
                    />
                  </div>
                )}
                {!loaded ? null : (
                  <>
                    <div className="mb-3">
                      <div>
                        <form>
                          <div className="form-row mx-4 align-items-center center justify-content-center">
                            <div className="form-group col-lg-4">
                              <label>
                                Date
                                {navigator.userAgent.indexOf("Safari") >= 1 &&
                                navigator.userAgent.indexOf("Chrome") <= 0 ? (
                                  <em>
                                    <small> (aaaa-mm-jj)</small>
                                  </em>
                                ) : null}
                              </label>
                              <input
                                type="date"
                                className="form-control rounded-pill"
                                placeholder={
                                  navigator.userAgent.indexOf("Safari") >= 1 &&
                                  navigator.userAgent.indexOf("Chrome") <= 0
                                    ? "aaaa-mm-jj"
                                    : null
                                }
                                pattern={
                                  navigator.userAgent.indexOf("Safari") >= 1 &&
                                  navigator.userAgent.indexOf("Chrome") <= 0
                                    ? "\\d{4}-\\d{2}-\\d{2}"
                                    : null
                                }
                                value={suivi.createdAt || ""}
                                onChange={(e) => {
                                  setSuivi({
                                    ...suivi,
                                    createdAt: e.target.value,
                                  });
                                }}
                                required
                              />
                            </div>
                          </div>
                          {user.role < 4 && (
                            <>
                              <div className="form-row mx-4 align-items-center center justify-content-center">
                                <div className="form-group col-lg-2">
                                  <div className="custom-control custom-switch">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="switchFilDeLEauEdit"
                                      checked={suivi.flow || false}
                                      onChange={() =>
                                        setSuivi((prev) => ({
                                          ...prev,
                                          flow: !prev.flow,
                                        }))
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="switchFilDeLEauEdit"
                                    >
                                      <strong>Fil de l'eau</strong>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {suivi.flow && (
                                <div className="form-row mx-4 align-items-center center justify-content-center">
                                  <div className="form-group col-lg-3">
                                    <label className="form-control-label">
                                      Evaluateur
                                    </label>
                                    <select
                                      // className="form-control  rounded-pill"
                                      className={`form-control  rounded-pill ${
                                        suivi.evaluateur === undefined ||
                                        suivi.evaluateur === null ||
                                        suivi.evaluateur === ""
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      aria-describedby={`evaluatorSelector`}
                                      value={suivi.evaluateur || ""}
                                      onChange={(e) =>
                                        setSuivi({
                                          ...suivi,
                                          evaluateur: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">
                                        {evaluateurs.length < 1
                                          ? "Chargement en cours ..."
                                          : "Selectionnez un évaluateur"}
                                      </option>
                                      {evaluateurs.map((ev, i) => (
                                        <option value={ev["@id"]} key={i}>
                                          {ev.name} {ev.firstName}
                                        </option>
                                      ))}
                                    </select>
                                    <div
                                      id={`evaluatorSelector`}
                                      className="invalid-feedback"
                                    >
                                      l'évaluateur est obligatoire !
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          <table className="mt-3 table table-sm table-responsive-md">
                            <tbody>
                              {blocs.sort(triByName).map((b, i) => {
                                let notes = suivi.notes.filter(
                                  (n) => n.competence.bloc["@id"] === b["@id"]
                                );
                                return (
                                  <Fragment key={i}>
                                    <tr>
                                      <th
                                        colSpan="7"
                                        className="bg-info text-white"
                                      >
                                        {b.name}
                                      </th>
                                    </tr>
                                    {b.competences
                                      .sort(triByName)
                                      .map((comp, i) => {
                                        let selected = notes.filter(
                                          (n) =>
                                            n.competence["@id"] === comp["@id"]
                                        );
                                        return (
                                          <Fragment key={i}>
                                            <tr className="text-left">
                                              <th colSpan="5">
                                                <FontAwesomeIcon
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    selected.length > 0
                                                      ? removeCompetence(comp)
                                                      : addCompetence(comp, b);
                                                  }}
                                                  className={
                                                    selected.length > 0
                                                      ? "text-danger"
                                                      : "text-success"
                                                  }
                                                  icon={
                                                    selected.length > 0
                                                      ? "arrow-circle-left"
                                                      : "arrow-circle-right"
                                                  }
                                                  title={
                                                    selected.length > 0
                                                      ? "Retirer la compétence"
                                                      : "Ajouter la compétence"
                                                  }
                                                />{" "}
                                                <em
                                                  className={
                                                    selected.length > 0
                                                      ? "text-success"
                                                      : null
                                                  }
                                                >
                                                  - {comp.name}
                                                </em>
                                              </th>
                                            </tr>
                                            {selected.map((n, i) => (
                                              <Fragment key={i}>
                                                {(n.note === undefined ||
                                                  n.note === null) && (
                                                  <tr>
                                                    <th
                                                      colSpan={4}
                                                      className=" border-0 p-0 m-0"
                                                    >
                                                      <small className="text-danger">
                                                        <strong>
                                                          Notation obligatoire.
                                                        </strong>
                                                      </small>
                                                    </th>
                                                  </tr>
                                                )}
                                                <tr key={i}>
                                                  {_EVAL.map((val, i) => (
                                                    <th
                                                      className="border-0 align-middle"
                                                      key={i}
                                                    >
                                                      <div className="form-check form-check-inline">
                                                        <input
                                                          type="radio"
                                                          className={`form-check-input${
                                                            n.note !==
                                                              undefined &&
                                                            n.note !== null &&
                                                            n.note >= 0
                                                              ? ""
                                                              : " is-invalid"
                                                          }`}
                                                          id={`${val.name}-${comp["@id"]}`}
                                                          name={
                                                            n.competence["@id"]
                                                          }
                                                          value={val.value}
                                                          onChange={(e) => {
                                                            editNote(
                                                              e.target.value,
                                                              b["@id"],
                                                              comp["@id"]
                                                            );
                                                          }}
                                                          checked={
                                                            n.note !== undefined
                                                              ? n.note ===
                                                                val.value
                                                              : false
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label"
                                                          htmlFor={`${val.name}-${comp["@id"]}`}
                                                        >
                                                          {val.name}
                                                          <span
                                                            className={`text-${val.color} ml-1`}
                                                          >
                                                            {val.icons.map(
                                                              (icon, i) => (
                                                                <FontAwesomeIcon
                                                                  icon={icon}
                                                                  key={i}
                                                                />
                                                              )
                                                            )}
                                                          </span>
                                                        </label>
                                                      </div>
                                                    </th>
                                                  ))}
                                                  <th className="form-group border-0">
                                                    <input
                                                      className={`form-control${
                                                        n.activities !==
                                                          undefined &&
                                                        n.activities !== null &&
                                                        n.activities.length >=
                                                          10
                                                          ? ""
                                                          : " is-invalid"
                                                      }`}
                                                      type="text"
                                                      name="commentaire"
                                                      value={n.activities || ""}
                                                      placeholder="Activités réalisées"
                                                      onChange={(e) => {
                                                        editComment(
                                                          e.target.value,
                                                          b["@id"],
                                                          comp["@id"]
                                                        );
                                                      }}
                                                    />
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <th
                                                    colSpan={5}
                                                    className="border-0"
                                                  >
                                                    <input
                                                      className="form-control"
                                                      type="text"
                                                      name="commentaire_pedago"
                                                      value={
                                                        n.commentaire || ""
                                                      }
                                                      placeholder="Commentaire pedagogique"
                                                      onChange={(e) =>
                                                        editNoteComment(
                                                          e.target.value,
                                                          b["@id"],
                                                          comp["@id"]
                                                        )
                                                      }
                                                    />
                                                  </th>
                                                </tr>
                                              </Fragment>
                                            ))}
                                          </Fragment>
                                        );
                                      })}
                                  </Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </form>
                      </div>
                      <div className="form-group">
                        <label>Commentaires</label>
                        <textarea
                          className={`form-control${
                            suivi.comment !== undefined &&
                            suivi.comment !== null &&
                            suivi.comment !== ""
                              ? ``
                              : ` is-invalid`
                          }`}
                          type="textarea"
                          rows="5"
                          placeholder={"Veuillez saisir un commentaire"}
                          value={suivi.comment || ""}
                          onChange={(e) =>
                            setSuivi({ ...suivi, comment: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  setLoaded(false);
                  setMsg({
                    txt: "Enregistremet en cours ...",
                    type: "warning",
                  });
                  handleSubmit();
                }}
                disabled={!validate()}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditSMEC;
