import { useContext, useEffect, useState } from "react";
import AppContext from "../../../Context/AppContext";
import GetBddProvider from "../../../Providers/GetBddProvider";
import SmallSpinner from "../../common/SmallSpinner";

const AbsEleveNoSync = ({ student, token }) => {
  const { urlEdusign } = useContext(AppContext);
  const [eleveEdusign, setEleveEdusign] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (student !== undefined) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = () => {
    let uri = urlEdusign.STUDENTS + "/" + student.studentId;
    GetBddProvider(uri, token).then((res) => {
      if (typeof res === "object" && res.status === "success") {
        let data = res["result"];
        setEleveEdusign(data);
      } else {
      }
      setLoading(true);
    });
  };

  return loading && eleveEdusign !== null ? (
    <li className="list-group-item list-group-item-warning">
      {`Elève non syncrhonisé (${eleveEdusign.FIRSTNAME} ${eleveEdusign.LASTNAME}) - RDV dans le menu Administration -> Sync Edusign`}
    </li>
  ) : (
    <SmallSpinner />
  );
};

export default AbsEleveNoSync;
