import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import AppContext from "../../../Context/AppContext";
import UpdateBddProvider from "../../../Providers/UpdateBddProvider";
import MsgAlert from "../../common/MsgAlert";
import SmallSpinner from "../../common/SmallSpinner";
import { autoCloseMsg } from "../../tools/messagesUtils";

const PromoItemGalia = ({
  action,
  promos,
  maj,
  actionSelected,
  setActionSelected,
  setPromo,
}) => {
  const { url } = useContext(AppContext);

  const assign = promos.filter((p) => p.idGalia === action.ID);

  const [save, setSave] = useState(true);
  const [promoSelected, setPromoSelected] = useState(null);
  const [msg, setMsg] = useState(null);

  const saveSync = () => {
    setSave(false);
    let uri = url + promoSelected.slice(1);
    let data = { idGalia: action.ID };
    update(uri, data);
  };

  const supSync = () => {
    if (actionSelected && actionSelected.ID === action.ID) {
      setPromo(null);
      setActionSelected(null);
    }
    setSave(false);
    let uri = url + assign[0]["@id"].slice(1);
    let data = { idGalia: null };
    update(uri, data);
  };

  const update = (uri, data) => {
    UpdateBddProvider(uri, data).then((res) => {
      if (typeof res === "object" && res.id) {
        setMsg({
          txt: "les données ont bien été modifiées",
          type: "success",
        });
        autoCloseMsg(setMsg, 2000);
        setSave(true);
        maj(true);
      } else {
        setSave(true);
        setMsg({ txt: "Erreur d'enregistrement", type: "danger" });
        autoCloseMsg(setMsg, 2000);
      }
    });
  };
  return (
    <li
      className={`list-group-item  list-group-item-${
        actionSelected && actionSelected.ID === action.ID ? "info" : "light"
      }`}
    >
      {msg !== null ? (
        <MsgAlert
          msg={msg.txt}
          type={msg.type}
          close={() => autoCloseMsg(setMsg)}
        />
      ) : null}
      <div className="d-flex justify-content-between">
        <div>
          <h6 className="m-0">
            {action.Libelle_Lieu_Court} - {action.Libelle_Court}
          </h6>
          {action.Date_Fin_Prevue === null && (
            <span className="text-danger font-small">
              (le champs "date de fin prévue" n'est pas renseigné dans Galia)
            </span>
          )}
          <div className="form-inline mt-2">
            <FontAwesomeIcon
              icon="sync-alt"
              className={`mr-2 ${
                assign.length !== 0 ? "text-success" : "text-warning"
              }`}
            />
            {assign.length === 0 ? (
              <>
                <select
                  className="form-control"
                  value={promoSelected || ""}
                  onChange={(e) => setPromoSelected(e.target.value)}
                >
                  <option value="" disabled>
                    Sélectionnez une promo
                  </option>
                  {promos
                    .filter((p) => !p.idGalia)
                    .map((promo) => (
                      <option
                        value={promo["@id"]}
                        key={`${action.ID}${promo["@id"]}`}
                      >
                        {promo.name}
                      </option>
                    ))}
                </select>
                <button
                  className="btn btn-outline-success btn-sm ml-2"
                  title="enregistrer"
                  onClick={() => {
                    saveSync();
                  }}
                  disabled={promoSelected === null}
                >
                  {save ? <FontAwesomeIcon icon="check" /> : <SmallSpinner />}
                </button>
              </>
            ) : (
              <>
                {assign[0].name}{" "}
                <button
                  className="btn btn-outline-danger btn-sm ml-2"
                  title="Désynchroniser"
                  onClick={() => supSync()}
                >
                  {save ? (
                    <FontAwesomeIcon icon="times" role="button" />
                  ) : (
                    <SmallSpinner />
                  )}
                </button>
              </>
            )}
          </div>
        </div>
        {assign.length === 0 ? null : (
          <div
            className={`btn btn-sm btn-${
              actionSelected && actionSelected.ID === action.ID
                ? ""
                : "outline-"
            }primary align-self-center`}
            onClick={() => {
              if (actionSelected && actionSelected.ID === action.ID) {
                setActionSelected(null);
                setPromo(null);
              } else {
                setActionSelected(action);
                setPromo(assign[0]);
              }
            }}
          >
            {actionSelected && actionSelected.ID === action.ID
              ? "Désélectionner"
              : "Sélectionner"}
          </div>
        )}
      </div>
    </li>
  );
};

export default PromoItemGalia;
