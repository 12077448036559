import React, { useContext } from "react";
import AppContext from "../../../Context/AppContext";
import { nl2br } from "../../tools/nl2br";
import { datasBuilder } from "../../tools/ReleveHeures/datasBuilder";
import { RelevePDF } from "../../tools/RelevePDF";

const RelFormateur = ({
  formateur,
  progressions,
  reunions,
  sites,
  debut,
  fin,
}) => {
  const { urlPdf } = useContext(AppContext);
  let releveDatas = datasBuilder(
    formateur,
    progressions,
    reunions,
    sites,
    debut,
    fin
  );

  return releveDatas !== null ? (
    <div className="animFadeIn">
      <div>
        <div className="mb-4">
          <button
            className="btn btn-outline-primary"
            onClick={() => RelevePDF(releveDatas, urlPdf)}
          >
            Export PDf
          </button>
          {/* <ReactHTMLTableToExcel
            className="btn btn-outline-primary"
            table="tableHeure"
            filename={
              "relevé-" +
              releveDatas.formateur.user.name +
              "-" +
              releveDatas.formateur.user.firstName +
              "-" +
              (new Date(debut).getMonth() + 1) +
              "-" +
              new Date(debut).getFullYear()
            }
            sheet="Export"
            buttonText="Export Excel"
          /> */}
        </div>

        <table
          className="table table-sm table-bordered table-responsive-md p-4"
          id="tableHeure"
        >
          <thead>
            <tr>
              <th className="text-primary" colSpan="6">
                <h2>
                  {releveDatas.formateur.user.name}{" "}
                  {releveDatas.formateur.user.firstName}
                </h2>
                du {releveDatas.debut} au {releveDatas.fin}
              </th>
            </tr>
          </thead>
          {releveDatas.datas.map((data, i) => {
            return (
              <React.Fragment key={i}>
                <thead>
                  <tr>
                    <th colSpan="6" className="text-white bg-primary">
                      <h4>{data.site.name}</h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th colSpan="6">
                      <h4>Cours</h4>
                    </th>
                  </tr>
                  <tr className="text-primary">
                    <th>Dates</th>
                    <th>Promos</th>
                    <th>Matières</th>
                    <th>Progressions</th>
                    <th>description</th>
                    <th>Heures</th>
                  </tr>
                  {data.cours.length < 1 ? (
                    <tr>
                      <td colSpan="5">Pas d'intervention sur la période</td>
                    </tr>
                  ) : (
                    <React.Fragment>
                      {data.cours.map((prog, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              {prog.createdAt} <br />
                              {prog.activate ? (
                                <span className="badge badge-pill badge-success">
                                  Réalisé
                                </span>
                              ) : (
                                <span className="badge badge-pill badge-warning">
                                  Non réalisé
                                </span>
                              )}
                            </td>
                            <td className="text-nowrap font-weight-bold">
                              {prog.promos.map((p, i, t) =>
                                t.length > 1 && i + 1 < t.length
                                  ? p.name + ", "
                                  : p.name
                              )}
                            </td>
                            <td className="text-left font-small">
                              {prog.progressionItem[0].matiere.name}
                            </td>
                            <td className="text-left font-small">
                              {prog.progressionItem[0].name}
                            </td>
                            <td className="text-left font-small">
                              {prog.description && nl2br(prog.description)}
                            </td>
                            <td>{prog.h}</td>
                          </tr>
                        );
                      })}
                      <tr className="text-primary">
                        <td colSpan="5" className="text-right font-weight-bold">
                          Sous-total
                        </td>
                        <td className="font-weight-bold">{data.coursH}</td>
                      </tr>
                    </React.Fragment>
                  )}
                  <tr>
                    <th colSpan="6">
                      <h4>Réunions/Conseils</h4>
                    </th>
                  </tr>
                  <tr className="text-primary">
                    <th>Dates</th>
                    <th colSpan="4">Objets</th>
                    <th>Heures</th>
                  </tr>
                  {data.reunions.length < 1 ? (
                    <tr>
                      <th colSpan="6">Pas de réunion/conseil sur la période</th>
                    </tr>
                  ) : (
                    <React.Fragment>
                      {data.reunions.map((r, i) => {
                        return (
                          <tr key={i}>
                            <td>{r.createdAt}</td>
                            <td className="text-left" colSpan="4">
                              {r.label}
                            </td>
                            <td>{r.h}</td>
                          </tr>
                        );
                      })}
                      <tr className="text-primary">
                        <td colSpan="5" className="text-right font-weight-bold">
                          Sous-total
                        </td>
                        <td className="font-weight-bold">{data.reunionH}</td>
                      </tr>
                    </React.Fragment>
                  )}
                  <tr className="text-primary">
                    <th colSpan="5" className="text-right">
                      <h4>Total des Heures pour {data.site.name}</h4>
                    </th>
                    <th>
                      <h4>{data.totalH}</h4>
                    </th>
                  </tr>
                </tbody>
              </React.Fragment>
            );
          })}
        </table>
      </div>
    </div>
  ) : null;
};

export default RelFormateur;
