export const RelevePDF = (datas, urlPdf) => {
  let my_form = document.createElement("form");
  my_form.method = "POST";
  my_form.action = `${urlPdf}/releveHeures`;
  my_form.target = "_blank";
  my_form.hidden = true;

  let my_tb = document.createElement("input");
  my_tb.type = "textarea";
  my_tb.name = "data";
  my_tb.hidden = true;
  my_tb.value = JSON.stringify(datas);
  my_form.appendChild(my_tb);

  document.body.appendChild(my_form);
  my_form.submit();
  document.body.removeChild(my_form);
};
