import convertDecimal from "../convertDecimal";
import dateFormated from "../dateFormated";
import heuresFormated from "../heuresFormated";

export const datasBuilder = (
  formateur,
  progressions,
  reunions,
  sites,
  debut,
  fin
) => {
  let datas = [];
  if (formateur !== undefined && progressions !== null && reunions !== null) {
    for (const site of sites) {
      if (formateur.user.sites.find((s) => s["@id"] === site["@id"])) {
        let totalH = 0;
        let coursH = 0;
        let reunionH = 0;
        let dataCours = [];
        let dataReunions = [];

        const cours = progressions.filter(
          (prog) => prog.promos[0].site === site["@id"]
        );
        for (const c of cours) {
          let h = parseFloat(heuresFormated(c.time));
          totalH += h;
          coursH += h;
          dataCours.push({
            ...c,
            h: convertDecimal(h),
            createdAt: dateFormated(c.createdAt),
          });
        }

        const reunionsSite = reunions.filter(
          (r) => r.site["@id"] === site["@id"]
        );
        for (const reunion of reunionsSite) {
          let part = reunion.participants.find(
            (p) => p.user["@id"] === formateur.user["@id"]
          );
          if (part) {
            let h = parseFloat(heuresFormated(part.time));
            totalH += h;
            reunionH += h;
            dataReunions.push({
              ...reunion,
              h: convertDecimal(h),
              createdAt: dateFormated(reunion.createdAt),
            });
          }
        }
        let data = {
          site: site,
          cours: dataCours,
          reunions: dataReunions,
          totalH: convertDecimal(totalH),
          coursH: convertDecimal(coursH),
          reunionH: convertDecimal(reunionH),
        };

        datas.push(data);
      }
    }
    return {
      formateur: formateur,
      debut: dateFormated(debut),
      fin: dateFormated(fin),
      datas,
    };
  }
  return null;
};
